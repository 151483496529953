<template>
  <div class="keyan">
    <div class="space"></div>
    <div class="content">
      <div class="bread">
        <div class="w">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }"
              >核心产品</el-breadcrumb-item
            >
            <el-breadcrumb-item class="active">科研系统</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
      <div class="main">
        <div class="w">
          <div class="left">
            <h1>关于科研</h1>
            <div class="divid"></div>
            <div class="bottom">
              <p>
                利用高效的网络信息化管理模式进行新的模式管理
                促进科研管理的提质增效
              </p>
            </div>
          </div>
          <img src="@/assets/imgs/keyan.png" alt="" />
        </div>
      </div>
    </div>

    <div class="section">
      <h1>什么是科研管理系统</h1>
      <div class="parts w">
        <div class="part">
          <p>全校统一的科研信息数据库</p>
          <span>提供快捷方便的数据查询和报表分析功能</span>
        </div>
        <div class="part">
          <p>流程化和业务处理平台</p>
          <span>能够实现流程化办公，审批流程动态化</span>
        </div>
        <div class="part">
          <p>科研信息展示</p>
          <span>加强科研管理部门的数据处理能力</span>
        </div>
        <div class="part">
          <p>科研成果管理及统计</p>
          <span>提高与科研人员的沟通协作效率</span>
        </div>
      </div>
    </div>

    <div class="product">
      <h1>丰富产品功能 精准匹配客户需求</h1>
      <div class="w img">
        <img src="@/assets/imgs/keyan/product.png" alt="" />
      </div>
    </div>

    <div class="manage">
      <h1>个性化管理 全流程管理</h1>

      <div class="lists w">
        <div
          class="list"
          v-for="(item, index) in manageList"
          :key="item.id"
          :style="{
            backgroundImage:
              'url(' + (currentIndex === index ? item.activeBg : item.bg) + ')'
          }"
          :class="{ active: currentIndex === index }"
          @click="handleCLick(index)"
        >
          <p>{{ item.title }}</p>
          <div class="divid"></div>
          <span>{{ item.desc }}</span>
        </div>
      </div>
    </div>

    <div class="choice">
      <h1>为什么选择科研信息化管理系统</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  components: {},
  data() {
    return {
      currentIndex: 0,
      manageList: [
        {
          id: 1,
          title: '纵向项目',
          desc: '管理内容包括立项项目、项目变更项目中检、项目结项、预算标准设置项目分类设置等',
          bg: require('@/assets/imgs/keyan/zx.png'),
          activeBg: require('@/assets/imgs/keyan/zx_a.png')
        },
        {
          id: 2,
          title: '横向项目',
          desc: '管理内容包括合同签审、合同签订、项目变更、进账合同结项、预算标准、合同类型设置等',
          bg: require('@/assets/imgs/keyan/hx.png'),
          activeBg: require('@/assets/imgs/keyan/hx_a.png')
        },
        {
          id: 3,
          title: '校级项目',
          desc: '管理内容包括项目立项、项目变更、预算标准、合同类型设置等',
          bg: require('@/assets/imgs/keyan/xj.png'),
          activeBg: require('@/assets/imgs/keyan/xj_a.png')
        },
        {
          id: 4,
          title: '经费项目',
          desc: '系统可实现经费认领、经费执行、经费预警等功能，在具备条件的情况下，能够实现科研系统与财务系统之间，经费在线上数据对接，提高线上跨部门业务办理的效率',
          bg: require('@/assets/imgs/keyan/jf.png'),
          activeBg: require('@/assets/imgs/keyan/jf_a.png')
        },
        {
          id: 5,
          title: '科研人员',
          desc: '用户端可通过系统，在线办理经费到账、经费认领、经费调整等业务，提高科研人员的办事效率',
          bg: require('@/assets/imgs/keyan/ky.png'),
          activeBg: require('@/assets/imgs/keyan/ky_a.png')
        },
        {
          id: 6,
          title: '管理人员',
          desc: '科研管理人员可通过系统经办经费认领、经费执行查看、经费预警设置等功能及操作，提升管理人员的工作便利性和管理效率',
          bg: require('@/assets/imgs/keyan/gl.png'),
          activeBg: require('@/assets/imgs/keyan/gl_a.png')
        }
      ]
    }
  },
  methods: {
    handleCLick(index) {
      this.currentIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.keyan {
  .space {
    width: 100%;
    height: 80px;
    background-color: #2474ea;
  }

  .content {
    .bread {
      display: flex;
      align-items: center;
      text-align: left;
      height: 60px;
      background-color: #2474ea;
      .active /deep/.el-breadcrumb__inner {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
      }

      /deep/.el-breadcrumb__inner {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
      }
    }
    .main {
      height: 680px;
      background-color: #2474ea;
      text-align: left;
      .w {
        display: flex;
        align-items: center;
        .left {
          padding: 130px 80px;
          box-sizing: border-box;
          h1 {
            font-style: normal;
            font-weight: 400;
            font-size: 40px;
            line-height: 36px;
            color: #ffffff;
          }
          .divid {
            width: 18px;
            height: 2px;
            background: #ffffff;
            margin: 74px 0 50px;
          }
          .bottom {
            width: 580px;
            p {
              font-style: normal;
              font-weight: 400;
              font-size: 24px;
              line-height: 48px;
              color: rgba(255, 255, 255, 0.9);
            }
          }
        }
        img {
          width: 600px;
          height: 500px;
        }
      }
    }
  }

  .section {
    width: 100%;
    height: 660px;
    padding: 100px 0 126px;
    box-sizing: border-box;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #2a2f32;
    }
    .parts {
      margin-top: 65px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      text-align: left;
      .part {
        padding: 45px 0 0 112px;
        box-sizing: border-box;
        height: 160px;
        width: 49%;
        background: #f5f9ff;
        border: 1px solid #d4dffe;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        color: #000000;
        p {
          font-size: 20px;
          margin-bottom: 8px;
        }
        span {
          font-size: 16px;
          opacity: 0.6;
        }
      }
    }
  }

  .product {
    width: 100%;
    height: 800px;
    background: #f5f9ff;
    padding: 64px 0 83px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #2a2f32;
    }
    .img {
      margin-top: 54px;
    }
  }

  .manage {
    height: 760px;
    padding: 108px 0 178px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 36px;
    color: #2a2f32;
    .lists {
      margin-top: 80px;
      display: flex;
      .list {
        height: 358px;
        flex: 1;
        margin-right: 16px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: all 0.3s linear;
        padding-top: 23px;
        box-sizing: border-box;
        &:hover {
          cursor: pointer;
        }
        &.active {
          flex: 2;
          padding: 32px 20px;
          box-sizing: border-box;
          p {
            text-align: left;
            color: #ffffff;
          }
          .divid {
            display: block;
          }
          span {
            display: block;
          }
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          text-align: center;
          color: #5385bc;
        }
        .divid {
          width: 26px;
          height: 4px;
          background: #ffffff;
          border-radius: 10px;
          margin: 12px 0;
          display: none;
        }
        span {
          display: none;
          text-align: left;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #ffffff;
        }
      }
    }
  }

  .choice {
    height: 800px;
    padding: 74px 0 111px;
    box-sizing: border-box;
    background-image: url('../../assets/imgs/keyan/why.png');
    h1 {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #2a2f32;
    }
  }
}
</style>
